import React, { useState, useEffect } from "react";
import axios from "axios";
import Hero from "./home-components/hero";
import HouseRules from "./home-components/house-rules";
import { useParams } from "react-router-dom";
import CustomerService from "./home-components/customer-service";

const Home = () => {
  const [propertyData, setPropertyData] = useState(null);
  const [rules, setRules] = useState("");
  const [images, setImages] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      window.location.href = "https://allianceventura.com/#notallowed";
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://us-central1-av-bbf.cloudfunctions.net/api/listings/houserules/${id}`
        );
        setPropertyData(response.data);
        setRules(response.data.publicDescription.houseRules);
        setImages(response.data.pictures || []);
      } catch (error) {
        console.error("Error fetching property data:", error);
        console.log(JSON.stringify(error.toJSON()));
      }
    };

    fetchData();
  }, [id]);

  let formattedText = rules.split("-").join("\n-");

  return (
    <div>
      <Hero images={images} propertyData={propertyData} />
      <HouseRules rules={formattedText} />
      <CustomerService />
    </div>
  );
};

export default Home;
