import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/global-components/navbar";
import Home from "./components/home";
import Fof from "./components/global-components/fof";
import Footer from "./components/global-components/footer";
import FofData from "./data/fof";
import ScrollToTop from "./components/global-components/test";

class Root extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:id" element={<Home />} />
            <Route
              render={() => {
                return <Fof data={FofData} />;
              }}
            />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("sandyapp"));
