import React from "react";
import QRCode from "qrcode.react";

const WifiQRCodeGenerator = ({ ssid, password, encryption }) => {
  const generateQRCode = () => {
    const wifiString = `WIFI:T:${encryption};S:${ssid};P:${password};;`;
    return wifiString;
  };

  return <QRCode value={generateQRCode()} fgColor="#041C36" />;
};

export default WifiQRCodeGenerator;
