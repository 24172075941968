import React, { useState } from "react";
import homedata from "../../data/home.json";

const HouseRules = ({ rules }) => {
  const [rulesContent] = useState(homedata.rules.houserules);

  return (
    <section id="house-rules" className="pt100 pb100">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
            <div className="section-title text-center mb60">
              <h2>House Rules</h2>
              <hr className="lines" />
              <p>
                To ensure that your stay is enjoyable and comfortable, we kindly
                ask all guests to adhere to the following house rules.
              </p>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-6">
                {rulesContent &&
                  rulesContent.featurePart1 &&
                  rulesContent.featurePart1.map((element, i) => (
                    <div key={i} className="media single-feature-item">
                      <div className="media-body">
                        <h5>{element.title}</h5>
                        <p>{element.desc}</p>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="col-md-6">
                {rulesContent &&
                  rulesContent.featurePart2 &&
                  rulesContent.featurePart2.map((element, i) => (
                    <div key={i} className="media single-feature-item">
                      <div className="media-body">
                        <h5>{element.title}</h5>
                        <p>{element.desc}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-md-12">
              <div className="media single-feature-item">
                <div className="media-body">
                  <h5>House Rules Note</h5>
                  <p className="md-p-10px-b" style={{ fontStyle: "italic" }}>
                    {rules}
                  </p>
                </div>
              </div>
            </div>

            <p className="center text-center md-p-10px-b">
              Please respect UAE law and culture during your stay.
              <br /> Please respect the home and furnishings of your host. We
              wish you a pleasant stay!
              <br /> – Alliance Ventura
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HouseRules;
