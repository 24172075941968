import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageSlider from "./slider";
import WifiQRCodeGenerator from "./wifi-qr";

const Hero = ({ propertyData, images }) => {
  return (
    <section id="home" className="text-left hero-section-1">
      <div className="container">
        <div className="row full-height align-items-center">
          {propertyData && (
            <div className="hero-content col-lg-6 p-100px-t p-50px-b md-p-10px-b">
              <h2 className="m-30px-b">{propertyData.title}</h2>
              <p className="m-50px-b md-m-30px-b">{propertyData.desc}</p>
              <p style={{ fontSize: 15 }}>
                <b>Code:</b> {propertyData.nickname}
                <br />
                <b>Address:</b> {propertyData.address.full}
                <br />
                <b>Parking:</b> {propertyData.parkingInstructions}
              </p>
              <div
                className="media single-feature-item"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 10,
                  alignItems: "center",
                }}
              >
                <div className="col-lg-6">
                  <div className="media-body" style={{ color: "#041C36" }}>
                    <h5 style={{ color: "#041C36", fontWeight: "200" }}>
                      WiFi Name
                    </h5>
                    <p>
                      <b>{propertyData.wifiName}</b>
                    </p>
                    <h5 style={{ color: "#041C36", fontWeight: "200" }}>
                      Password
                    </h5>
                    <p>
                      <b>{propertyData.wifiPassword}</b>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="media-body" style={{ color: "#041C36" }}>
                    <WifiQRCodeGenerator
                      ssid={propertyData.wifiName}
                      password={propertyData.wifiPassword}
                      encryption="WPA"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="hero-image col-lg-6 p-100px-t p-50px-b md-p-10px-t">
            <ImageSlider images={images} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
