import React, { Component } from "react";

class CustomerService extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL;

    const inlineStyle = {
      backgroundImage: "url(" + publicUrl + ")",
    };

    return (
      <section
        id="customer-service"
        className="pt100 pb100 bg-parallax"
        style={inlineStyle}
      >
        <div className="color-overlay opacity-8"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 aboutIntroText color-white text-center">
              <h2 className="m-30px-b">24/7 Customer Service</h2>
              <p className="m-50px-b md-m-30px-b">
                Do you have a question or do you need help? Our customer service
                is ready for you 24/7.
              </p>
              <a href="tel:+971 58 581 6323" className="primary-btn">
                Call us 24/7
              </a>
              <a
                href="https://wa.me/+971585816323?text=Hello, I'm staying in $property and have a question or need assistance.&subject=Customer Service Request"
                className="primary-btn primary-btn-3"
                style={{ backgroundColor: "green" }}
              >
                Send message on WhatsApp
              </a>
            </div>
          </div>
        </div>
        <div className="bottom-pattern d-none d-lg-block">
          <img
            src={`${publicUrl}/assets/img/bg-pattern-light.png`}
            alt="ng pattren"
          />
        </div>
      </section>
    );
  }
}

export default CustomerService;
